.footer {
    border-top: 3px solid #9acd32; /* Upper border */    
    border-bottom: 3px solid #9acd32; /* Upper border */    
    color: #080809;
    background-color: rgb(248, 244, 236);
}

.footer-row {
    text-align: center;
}

.footer-section {
    padding: 15px;
    color: #080809;
    border-right: 2px solid #9acd32;
    transition: background-color 0.3s ease;

}
.footer-section:last-child {
    border-right: none;
}

  .footer-sectionss{
    width: 1000px;
  }

  /* Custom modal styles */
.modelbodyaboutusss .modal-dialog {
    max-width: 900px; /* Set maximum width to 900px */
    width: 100%; /* Ensure it takes full width up to max-width */
    z-index: 10;
    margin-top: 70px;
    padding: 2px;
  }