/* Position the Login Button at the top-right corner */
.loginDrop {
    position: fixed; /* Fixed position */
    top: 20px; /* Distance from the top */
    right: 20px; /* Distance from the right */
    z-index: 11; /* Ensure it appears on top */
    border: none; /* No border */
    display: flex; /* Center icon */
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
  }
  
  /* General styling for the page */
  .Appss {
    font-family: Arial, sans-serif;
    padding: 0px;
  }
  
  /* Styling for the dropdown menu (optional adjustment) */
  .DropMenuCs {
    padding: 0%;
    margin: 0%;
  }
  
  /* Dropdown styling */
  .login-dropdown {
    display: flex;
    justify-content: center;
    padding: 0px;
  }
  
  .modalBodyCss {
    padding: 20px;
  }
  
  .ccddfds{
    margin: 0%;
  }
  /* Optional: Styling for smaller devices (mobile) */
  @media (max-width: 768px) {
    .modallogincs {
      width: 80%; /* Make modal smaller on mobile */
      margin: 0px;
    }
  }
  