@media (min-width: 1024px) {
  .divformdata{
    width: 60%;
  }
}

@media (max-width:  576px) {
    .headdivsample{
        margin-top: 40px;
    }
}


/* Watercolor background using pure CSS gradient and blur effects */
.watercolor-container {
    position: relative;
    padding: 20px;
    background: linear-gradient(45deg, rgba(123, 255, 179, 0.5), rgba(255, 201, 179, 0.5), rgba(179, 227, 255, 0.5));
    background-size: 200% 200%;
    animation: watercolorBackground 6s ease infinite; /* Smooth animation effect */
  }
  
  @keyframes watercolorBackground {
    0% {
      background-position: 0% 0%;
    }
    50% {
      background-position: 100% 100%;
    }
    100% {
      background-position: 0% 0%;
    }
  }
  
  .watercolor-container::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.8); /* Light overlay for the watercolor effect */
    filter: blur(10px); /* Apply blur to soften the colors */
    z-index: -1; /* Make sure it's behind the content */
  }
  