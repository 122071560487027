.scrolling-container {
    overflow: hidden;
    position: relative;
    white-space: nowrap;
    background-color: rgba(208, 208, 208, 0.467);
    width: 100%;
    padding: 0%;
    margin-top: 1px;
  }
  
  
  /* Default scrolling animation */
  .scrolling-row {
    display: inline-flex;
    animation: scroll 40s linear infinite;
    padding: 0%;
  }
  
  
  /* Stop scrolling when the user hovers over the scrolling container */
  .scrolling-container:hover .scrolling-row {
    animation-play-state: paused; /* Pause the animation on hover */
  }
  
  
  .card-wrapper {
    display: inline-block; /* Ensure cards are inline for scrolling */
    margin: 1px; /* Add margin to space out cards */
  }  
  
  
  /* kmckmkk */
  .cardClass {
    background-color: transparent;
    border: none;
    cursor: pointer; /* Makes it look clickable */
    transition: transform 0.3s ease, box-shadow 0.3s ease; /* Smooth transition for hover effects */
  }
  
  
  .cardClas:hover {
    transform: scale(1.05); /* Slightly enlarges the card on hover */
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.2); /* Adds a shadow effect to the card */
  }
  /* njinijnhb */
  
  
  
  
  .fit-content-button {
    width: auto;
    padding: 5px 15px;
    display: inline-block;
  }
  
  
  .cardTitle, .cardText {
    background-color: rgba(255, 255, 255, 0.6);
    padding: 10px;
    border-radius: 5px;
    display: inline-block;
  }
  
  
  @keyframes scroll {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-50%); /* Adjust based on the total width of the row */
    }
  }
  
  
  .ddd {
    background-color: rgba(84, 83, 83, 0.6);
  }
  
 
  
  
  
  
  