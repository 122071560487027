

.strongOurs{
    font-family: Math-Italic;
    font-Size: 30px;
    color: #ffad00;
    text-align: center;
   


}
.strongOurss{
    text-align: left;
    border-bottom: 4px solid #9acd32;
}

p{
    line-height: 27px;
    color: #080809;
    font-size: 15px;
    text-align: left;
}

.corner-border-card {
    position: relative;
    border: none; /* Remove the full border */
    background-color: #f4f3f3;
}


.corner-border-card::before,
.corner-border-card::after {
    content: "";
    position: absolute;
    width: 60px;
    height: 60px;
    border: 5px solid #9acd32; /* Color and thickness of corner borders */
}


.corner-border-card::before {
    top: 0;
    left: 0;
    border-right: none;
    border-bottom: none;
}


.corner-border-card::after {
    bottom: 0;
    right: 0;
    border-left: none;
    border-top: none;


}