/* The container that holds the image */
.containeronliness {
    position: relative;

  }
  
  /* Style for the image */
  .onlineimage {
    width: 100%;  /* Ensure the image fills the container */
    height: auto; /* Maintain aspect ratio */
  }
  
  /* Style for the h1 text */
  .h1online {
    position: absolute;
    width: fit-content;
    top: 50%; /* Center the text vertically */
    left: 50%; /* Center the text horizontally */
    transform: translate(-50%, -50%); /* Correct the exact center */
    color: #080809; /* Make the text white for visibility */
    font-size: 2.5rem; /* Adjust the font size */
    text-align: center; /* Center the text */
    z-index: 10; /* Ensure it appears on top of the image */
  }

  .buttonright{
        background-color: #9acd32;
        padding: 2px;
        position: absolute;
        top: 50%;  
        right: 2px;  
        transform: translateY(-50%); 
        z-index: 10;
        height: 70px;
        width: 93px;
        font-size: larger;
        font-family: 'Times New Roman', Times, serif;
        font-weight: bold;     
  }

  /* Hover effect for the button */
.buttonright:hover {
    transform: translateY(-60%) scale(1.1); /* Move the button up and scale it */
    box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.3); /* Increase shadow on hover */
    cursor: pointer; /* Change cursor to pointer for better UX */
    background-color: #080809;
  }
  @media(min-width: 749px){
    .buttonright{
        right: 100px;
        width: 130px;
    }
  }

@media (max-width: 576px) {
.onlineimage{
 height: 150px;
      }

      .buttonright{
        right: 0px;
        
    }
    .h1online{
      font-size: 16px;
      width: fit-content;
    }

}

