.container-bg-color {
  display: inline;
  align-items: center;
  object-fit: cover;
}

.row-item {
  background-color: #9ACD32; /* Background color for the row */
  width: 50%;
  max-height: 40px;
  margin-top: 35px;
  padding-left: 4px;
  padding-right: 4px;

}

.col-item {
  background-color: #f0f0f0; /* Background color for the circles */
   margin-top: -27px;
  box-shadow: rgba(0, 0, 0, 0.1); /* Optional: add shadow */
  width: 90px;
  height: 90px;
  border-radius: 50%;
  padding: 0%;

}
.colsecondcol{
  width: 90px;
}
.rowitemss{
  width: 50%;
}

.class-button {
  margin-top: 5px; /* Space between the image and the button */
  font-size: 12px; /* Button text size */
  background-color: #004953; /* Button background color */
  color: white; /* Text color for better contrast */
  border: none; /* Remove default button border */
  padding: 5px 10px; /* Padding for button */
  border-radius: 5px; /* Slightly rounded corners for button */
}

.class-button:hover {
  background-color: #9ACD32; /* Hover effect */
}

.my-carousel img {
  height: 250px; /* Height for carousel images */
  object-fit: cover; /* Fit images without distortion */
}

/* Responsive Design */
@media (max-width: 768px) {
  .row-item {
      max-height: 400px; /* Smaller height for medium screens */
  }
}

@media (max-width: 576px) {
  .row-item {
   max-height: 100px; /* Smaller height for small screens */
    width: 100%;
    height: 40px;
    padding: 0%;
    }

  .col-item {
      width: 80px; /* Adjust circle size for small screens */
      height: 80px; /* Adjust circle size for small screens */
      margin-top: -20px;
    }
  .container-bg-color{
    object-fit: cover;
  }
  .colsecondcol{
    width: 106px;
  }
  .rowitemss{
    width: 100%;
  }
  .colomn3{
    align-items: left;
  }

}

