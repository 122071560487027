/* Make the container scrollable horizontally */
.scroll-container {
  display: flex;
  overflow-x: auto;
  background-color: rgba(208, 208, 208, 0.467);
  white-space: nowrap; /* Prevent wrapping to a new line */
  scroll-behavior: smooth; /* Smooth scrolling */
  padding: 10px 0;
  margin: 0;
}

/* Hide default scrollbar */
.scroll-container::-webkit-scrollbar {
  display: none;
}

/* Card styling */
.cardClass {
  min-width: 200px; /* Set a minimum width to each card */
  margin-right: 15px; /* Add some space between the cards */
}

.card-link {
  text-decoration: none;
  color: inherit;
}

  
  /* Style for the cards */
  .cardClass {
    background-color: transparent;
    border: none;
    cursor: pointer; /* Make the card appear clickable */
    transition: transform 0.3s ease, box-shadow 0.3s ease; /* Smooth transition for hover effects */
    width: 250px;
  }
  
  
  /* Hover effect on cards */
  .cardClass:hover {
    transform: scale(1.05); /* Slight zoom effect on hover */
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.2); /* Add subtle shadow on hover */
    width: 250px;
  }
  
  /* Style for the heading */
  .h2class {
    padding-left: 50px;
    margin: 0px;
    color: #080809;
    font-family: 'Math-Italic';
    font-weight: bold; /* Make sure the font appears bold */
  }
  
  
  
  
  