.cardTitle {
    background-color: rgba(255, 255, 255, 0.8); /* White with 60% opacity */
    border-radius: 5px;
    display: inline-block;
  }
 
  .cardText {
    background-color: rgba(255, 255, 255, 0.8); /* White with 60% opacity */
    border-radius: 5px;
    display: inline-block;
    margin: 0%;
  }
 
  .cardClas {
    background-color: transparent; /* Set the card background as transparent */
    border: none; /* Remove card borders if needed */
  }
 
  .cardClasss{
    background-color: #9ACD32;
  }
  .cardbutton{
    background-color: #004953;
    margin-top: 10px;
    position: relative;
    top: 15px;
    left: 25px;
    padding: 0px;
    margin: 1px;
    width: auto;
  }
  .cardImagg{
    height: 180px;
    width: 180;
  }
  .cardDivClass{
    padding: 0px;
  }
  .cardCodyClass{
    padding: 1px;
  }


