/* Define the scale and fade-in animation */
@keyframes scaleInFade {
    0% {
      opacity: 0;
      transform: scale(0.6); /* Start smaller */
    }
    100% {
      opacity: 1;
      transform: scale(1); /* Scale to normal size */
    }
  }
  
  /* Apply the scale and fade-in animation to each point */
  .points-list-card {
    opacity: 0; /* Initially hidden */
    animation: scaleInFade 0.5s ease-out forwards; /* Apply scale and fade effect */
    margin-bottom: 10px; /* Space between each point */
  }
  
  /* Delay animation for each point */
  .points-list-card:nth-child(1) {
    animation-delay: 0.5s;
  }
  
  .points-list-card:nth-child(2) {
    animation-delay: 1s;
  }
  
  .points-list-card:nth-child(3) {
    animation-delay: 1.5s;
  }
  
  .points-list-card:nth-child(4) {
    animation-delay: 2s;
  }
  
  .points-list-card:nth-child(5) {
    animation-delay: 2.5s;
  }
  

.badges{
    font-size: 30px;
    color: #ffad00;    
    line-height: 1.1; 
   

}

.cardcss {
    width: 21rem;
    border: none;
    height: fit-content;
 
  

}

.points-list-card {
    background-color: rgba(244, 244, 244, 0.5);  /* Set 50% transparency */
    padding: 10px 15px;           /* Padding inside the background */
    margin: 5px 0;               /* Margin between points */
    display: block;              /* Ensures each point appears in a block (stacked vertically) */
    width: auto;                 /* Auto width based on content */
    border-radius: 5px;          /* Optional: Add rounded corners */
    text-align: center;          /* Center text horizontally */
    color: #080809;
}

  
  
.cardcsstitle{
    /* color: #f8f8fd; */
    background-color: transparent;
    /* background-color: rgb(248, 244, 236); */
    font-weight: bold;
    border: none;
    box-shadow: none;
  
}

@media(max-width: 2560px){

    .badges{
        font-size: 44px;
        font-weight: 600;
    
        border-radius: 10px;
        margin-left: 20px;
    }
    .cardcolomncss{
        margin-top: 40px;
    }
    .points-list{
        font-size: 20px;
    }

    .cardcsstitle{
        font-size: 26px;
    }

    .points-list-card{
        font-size: 22px;
    }

       
}

@media(max-width: 1440px){
    .badges{
        font-size: 44px;
        font-weight: 600;
        padding-left: 30px;
        border-radius: 10px;
    
    }
    .cardcolomncss{
        margin-top: 34px;
    }

    .cardcsstitle{
        font-size: 24px;
    }

    .points-list-card{
        font-size: 21px;
    }


    
}

@media(max-width: 1200px){
    .badges{
        font-size: 32px;
        font-weight: 600;
        padding-left: 30px;
        border-radius: 10px;
    
    }
    .cardcolomncss{
        margin-top: 33px;
    }
    .cardcsstitle{
        font-size: 20px;
    }

    .points-list-card{
        font-size: 18px;
    }

  
}

@media(max-width: 992px){
    .badges{
        font-size: 28px;
        font-weight: 600;
        padding-left: 30px;
        border-radius: 10px;
    
    
    }
    .cardcolomncss{
        margin-top: 30px;
    }
    .cardcsstitle{
        font-size: 18px;
    }

    .points-list-card{
        font-size: 16px;
    }

    .cardcss{
        width: 19rem;
       
    }
}

@media(max-width: 768px){

    .badges{
        font-size: 27px;
        font-weight: 600;
        
        border-radius: 10px;
    
    
    }
    .cardcolomncss{
        margin-top: 30px;
    }
    .cardcsstitle{
        font-size: 19px;
    }

    .points-list-card{
        font-size: 15px;
    }

    .cardcss{
        width: 19rem;
       
    }
}

@media(max-width: 576px){
    .badges{
        font-size: 18px;
        font-weight: 600;

        border-radius: 10px;
        
    
    }
    .cardcolomncss{
        margin-top: 20px;
    }
    .cardcsstitle{
        font-size: 18px;
    }

    .points-list-card{
        font-size: 15px;
    }

    .cardcss{
        width: 19rem;
       
    }
    
}


