.containerCareer {
    font-family: 'Merriweather', serif;
    border: black solid 1px;
    padding-bottom: 10px;
  }
  
  /* Title Style */
  .containerCareer h2 {
    font-size: 48px;
    color: #333;
    font-weight: 700;
    display: inline-block;
    padding: 10px;
  }
  
  /* Style for the card body titles (subheadings) */
  .shadowBodyTitle {
    font-size: 22px;  /* Subheading size */
    font-weight: 600;  /* Semi-bold weight */
    display: flex;
    align-items: center;
    color: #333;
  }
  
  .shadowBodyTitle svg {
    color: #ffad00;  /* Arrow color */
    font-size: 1.5rem; /* Adjust size of the arrow */
    margin-right: 10px;  /* Space between arrow and title */
  }
  .shadowBodyTitle svg {
    font-size: 24px; /* You can adjust this value to get the desired icon size */
  }
  
  /* List items style inside the cards */
  .cardmain ul li {
    font-size: 14px;  /* Paragraph size */
    color: #555;  /* Slightly lighter color for paragraphs */
    margin-bottom: 5px;
  }
  
  /* Card Main Styling */
  .cardmain {
    display: flex;
    flex-direction: column;
    height: 100%;
    margin-bottom: 0;
  }
  
  .shadowBody {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    margin-bottom: 0;
    padding: 10px;
  }
  
  /* Media Queries for Responsive Design */
  @media(max-width: 1440px){
    .containerCareer h2 {
      font-size: 40px;
    }
    .shadowBodyTitle {
      font-size: 20px;
    }
    .cardmain ul li {
      font-size: 14px;
    }
  }
  
  @media(max-width: 1024px){
    .containerCareer h2 {
      font-size: 36px;
    }
    .shadowBodyTitle {
      font-size: 19px;
    }
    .cardmain ul li {
      font-size: 13px;
    }
  }
  
  @media(max-width: 992px){
    .containerCareer h2 {
      font-size: 32px;
    }
    .shadowBodyTitle {
      font-size: 18px;
    }
    .cardmain ul li {
      font-size: 13px;
    }
  }
  
  @media(max-width: 768px){
    .containerCareer h2 {
      font-size: 28px;
    }
    .shadowBodyTitle {
      font-size: 17px;
    }
    .cardmain ul li {
      font-size: 12px;
    }
  }
  
  @media(max-width: 576px){
    .containerCareer h2 {
      font-size: 24px;
    }
    .shadowBodyTitle {
      font-size: 15px;
    }
    .cardmain ul li {
      font-size: 12px;
    }
  }
  