.dropdown-item {
    border: 1px solid #007bff; /* Adjust the border color to your preference */
    margin: 2px 0; /* Adds some space between the items */
    border-radius: 5px; /* Optional: Makes the border edges rounded */
  }
  
  /* Show dropdown on hover */
.dropdown:hover .dropdown-menu {
    display: block;
  }
  .Dropdown-Menu{
    padding: 0%;
    margin: 0%;
  }
  /* Optional: Adjust styling if needed */
  .dropdown-toggle::after {
    transition: transform 0.3s ease;
  }
  
  /* Rotate caret when dropdown is open */
  .dropdown:hover .dropdown-toggle::after {
    transform: rotate(180deg);
  }
  .class-button {
    margin-top: -10px; /* Space between the image and the button */
    font-size: 12px;
    background-color: #004953; /* Optional: Adjust the button text size */
  }  