/* General Styling for the Feedback Form */
.feedback-form {
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    /* background-color: #f9f9f9; */
    border-radius: 8px;
  }
  
  h2 {
    margin-bottom: 20px;
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  label {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
  }
  
  input[type="text"],
  input[type="email"],
  select,
  textarea {
    width: 100%;
    padding: 8px;
    font-size: 14px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  button {
    background-color: #4CAF50;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  button:hover {
    background-color: #45a049;
  }
  
  .error {
    color: red;
    font-size: 12px;
  }
  
  /* Rating Options Styling */
  .rating {
    display: flex;
    justify-content: space-between; /* Spread radio buttons horizontally */
    margin-top: 10px;
  }
  
  .rating-option {
    display: flex;
    flex-direction: column; /* Stack number and radio button vertically */
    align-items: center;
    margin-right: 10px;
  }
  
  .rating-option label {
    margin-bottom: 5px;
  }
  
  textarea {
    resize: vertical;
  }
  
  select {
    height: 35px;
    font-size: 14px;
  }
  