/* Style for the container */
.navigation-links {
  display: flex;
  flex-direction: column;      /* Stack the items vertically */
  justify-content: center;     /* Center the items vertically */
  align-items: center;         /* Center the items horizontally */
  margin: 0;
  padding: 0;
}

/* Styling for each list item */
.list-item {
  padding: 2px;
  text-decoration: none;
  color: #333; /* Or any color you prefer */
  cursor: pointer;
  font-size: 16px;
  width: 100%; /* Make them full width to align the text center */
  text-align: center; /* Center text inside each item */
}

/* Additional styling for <a>, <p>, and <Link> */
.link-item {
  display: inline-block;
  transition: color 0.3s ease;
}

.link-item:hover {
  color: #9acd32;  /* Color change on hover */
}

.link-item:focus {
  outline: none; /* Optional: remove focus outline */
}


/* Specific styling for the <p> element to look like a link */
p.link-item {
  text-decoration: none; /* Make <p> appear like a link */
  cursor: pointer; /* Make <p> clickable */
  padding: 0px; /* Ensure padding is the same as other items */
  margin: 0%;
}