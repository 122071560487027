.whatsapp-button {
    position: fixed; /* Fixed position */
    bottom: 20px; /* Distance from the bottom */
    right: 20px; /* Distance from the right */
    z-index: 1000; /* Ensure it appears on top */
    background-color: transparent; /* No background */
    border: none; /* No border */
    display: flex; /* Center icon */
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
  }
  