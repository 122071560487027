.social-media {

    justify-content: center;
    gap: 15px;

}

.social-media-p a{
    display: inline;
    padding: 10px;
    gap: 10px;
    margin: 10px;
}
.social-media-p{
    margin-top: 20px;
}
.social-link {
    color: #080809;
    transition: color 0.3s ease;
}

.social-link:hover {
    color: #007bff; /* Change color on hover */
}
