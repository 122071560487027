/* Dashboard.css */

.dashboard-container {
    overflow: hidden;
  }
  
  .sidebar-container {
    position: sticky;
    top: 0;
    background-color: #f8f9fa;
    overflow-y: auto;
    padding-top: 1rem;
  }
  
  .main-content-container {   
    height: 100vh;
    overflow-y: auto;
  }
  