.text-align-right {
  display: flex;
  justify-content: flex-end; /* Aligns the content to the right */
}


h2.button-like {
  background-color: #9ACD32;  /* Green background color */
  color: white;               /* White text color */
  padding: 10px 20px;         /* Add padding for button-like appearance */
  border-radius: 5px;         /* Rounded corners */
  text-align: center;         /* Center the text */
  display: inline-block;      
  cursor: pointer;           /* Change cursor to pointer when hovered */
  font-size: 18px;            
  border: none;               /* Remove any borders */
  border-radius: 15px;

}

h2.button-like:hover {
  background-color: #45a049;  /* Darker green on hover */
}

.enquirenow:hover{
     background-color: #1515ec;
     color: #ffad00;
     transform: translateY(-60%) scale(1.1); /* Move the button up and scale it */
     box-shadow: 0px 6px 15px rgba(180, 186, 180, 0.3); /* Increase shadow on hover */
     cursor: pointer; /* Change cursor to pointer for better UX */
     
}

.language-linkss {
  display: inline-block; /* Makes it behave like a button */
  color: #080809; /* Text color */
  border-radius: 5px; /* Rounded corners */
  text-decoration: none; /* Remove underline */
  transition: background-color 0.3s; /* Smooth transition */
  font-size: 25px;
  font-family: math-italic;
  font-weight: 600;
}

.language-linkss:hover {
  color: #ffad00; /* Darker shade on hover */
} 
.freambg{
    background-image: url ("./images/textframe.png");
    background-size: cover;
    background-position: center;
    height: 100vh; /* Full viewport height */
    color: #f7f7f7; /* Text color for contrast */
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .classcolomnborder{
  
    margin-top: 90px;
    margin-bottom: 24px;
    font-family: 'Times New Roman', Times, serif;
  }

  

.onlinelanguagepng{
    max-width:  90px;
    max-height: 90px;
}
.namelanguage{
  color: #ffad00;
  /* text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5); */
}

.headding{
    font-size: 50px;
}

.frenchaisies{
    height: fit-content;
}

.position-relative {
    position: relative;
  }
  
  .text-overlay {
    position: relative;
    left: 100px; /* Adjust this value to move the text left or right */
    transform: translateY(-50%); /* Center the text vertically */
    color: black; /* Change text color for visibility */
    border-radius: 5px; /* Optional: rounded corners */
  }
  .CardImmm{
    width:266px;
    height: 230px;
  }

  .cardcontainer{
    width: fit-content;
    height: fit-content;
    /* background-color: #E0E0E0; */
    background-color: rgb(248, 244, 236);
    text-align: center; /* Center text */
   border: 2px solid #9ACD32; /* Optional: Add a border for visibility */  
  }

  .imagegirls{
    height: 500px;
  }

  .buttoncourse{
    width: 100%;
    font-size: larger;
    font-family: 'Times New Roman', Times, serif;
    font-weight: bold;
    background-color: #9ACD32;
  }

  .fitContents{
    width: 550px;
    height: 350px;
  }
  .custom-modal-styles{
    background-color: white;
  }
  .custom-modal-styles .modal-content, .modal-header{
    background-color: transparent; /* Make modal content transparent */
  }

  .colomnimagesss{
    margin-bottom: 24px;  
  }

  .card-img-customm {
    width: 100%; /* Default to fill the container width */
    height: auto; /* Maintain aspect ratio */
  
    /* Set specific size for screens between 1024px and 1500px */
    @media (min-width: 1024px) and (max-width: 1920px) {
      width: 146px;
      height: 146px;
    }
  }
  .enquirenow{
    @media(min-width: 768px) and (max-width: 1920px){
      font-size: 28px;
    }
  }

  .enquirenow{
    @media(min-width: 320px) and (max-width: 768px){
      font-size: 16px;
    }
  }
  

  @media(max-width: 2560px){
    .frenchaisies{
      height: fit-content;
     
    }
    .colomnclassname{
      max-width:19%;   
      margin-left: 0px;
      margin-right: 0px;
    
    }
    .cardcontainer{
      max-width: fit-content;
      margin-bottom: 10px;
    }

  }
  @media (max-width: 2000px) {
    .frenchaisies{
      height: 370px;     
    }
  }
    @media (max-width: 1438px) {
      .frenchaisies{
        height: 350px;       
      }
      .colomnclassname {
        max-width:20%;
        margin-left: 0px;
        margin-right: 0px;
      }
      .onlinegirlsimg{
        padding-left: 90px;
      }
    }
    
    @media(max-width: 1024px){
      .frenchaisies{
        height: 270px;
       
      }
  
      .colomnclassname {
        max-width:28%;
        margin-left: 0px;
        margin-right: 0px;
      }
      .cardcontainer{
        max-width: fit-content;
        margin-bottom: 10px;
      }
      .CardImmm{
        max-width: 266px;
      }
      
  
    }

  @media(max-width: 992px){
    .frenchaisies{
      height: 250px;
    }
    .colomnclassname {
      max-width:28%;
      margin-left: 0px;
      margin-right: 0px;
    }
    .cardcontainer{
      max-width: fit-content;
    }
    .CardImmm{
      max-width: 256px;
    
    }

  }
  @media(max-width: 768px){
    .frenchaisies{
      height: 200px;
    }
    .colomnclassname{
      max-width: fit-content;
    }
    .cardcontainer{
      max-width: fit-content;
    }
    .CardImmm{
      max-width: 254px;
    }
  }
  @media(max-width: 576px){
    .frenchaisies{
      height: 150px;
    }
    .logoLanguage{  
        /* position: absolute; */
        position: relative;
        top:  100px;
        right: 100px;
        /* max-width: 150px;  */
        Z-index: 10;  
    }
    .onlinegirlsimg{
      width: 300px;
      height: 400px;
    }
    .classcolomnborder h3{
      font-size: 16px;
      
     }
     .classcolomnborder{
      margin-top: 8px;
      margin-bottom: 10px;
     }
     .cardcontainer{
      margin-top: 10px;
     }
     .CardImmm{
      max-width: 260px;
    }
    .colomnimagesss{
      margin-bottom: 0px;
    }
  }
  .join-text {
    font-family: "times new roman";
    font-size: 60px;
    color: #ffad00;
  }
  
  .our-text {
    font-family: "times new roman";
    font-size: 35px;
  }
  
  .training-text {
    font-family: "Baguet Script";
    font-size: 50px;
    color: #9acd32;
  }
  
  .today-text {
    font-family: "times new roman";
    font-size: 35px;
  }
  
  
  /* Media query for screens smaller than 576px */
  @media (max-width: 576px) {
    .ourjoin{
      padding-left: 20px;
    }
    .join-text {
      font-size: 30px; /* Adjust font size */
    }
  
    .our-text {
      font-size: 25px; /* Adjust font size */
    }
  
    .training-text {
      font-size: 30px; /* Adjust font size */
    }
  
    .today-text {
      font-size: 20px; /* Adjust font size */
    }
  }
  
 