.corporate-links {
    text-align: center;
    color: #080809;
    background-color: none;
}

.corporate-links h5 {
    margin-bottom: 10px;
    /* color: #080809; */
}

.corporate-link {
    color: #080809;
    text-decoration: none;
    transition: color 0.3s ease;

}

.corporate-link {
    background-color: none;
    background: none;
    border: none;
    color: #080809;
    cursor: pointer;
    padding: 0;
    font: inherit; /* Inherit the font styles so it matches the surrounding text */
  }
  
  .corporate-link:hover {
    color: #9acd32; /* Optional: Change color on hover */
    background-color: none;
    background: none;
  }
  
  .corporate-link:focus {
    outline: none; /* Optional: Remove focus outline */
    background-color: none;
    
  }

.custom-modal .modal-dialog {
    margin: 5px; /* Remove default margin */
    position: relative; /* Fix the position */
    top: 0%; /* Center vertically */
    left: 50%; /* Center horizontally */
    transform: translate(-50%, -50%); /* Adjust position to center */
    max-width: fit-content; /* Fit modal to content */
    width: auto; /* Allow width to adjust based on content */
    
}

.custom-modal .modal-content {
    border: none; /* Remove border if you want a clean look */
}

.custom-modal .modal-header,
.custom-modal .modal-footer {
    padding: 0; /* Remove padding */
}

.modal-body {
    padding: 0; /* Remove body padding */
}

@media (max-width: 576px) {
    .custom-modal .modal-dialog {
        height: 100%; /* Make sure it occupies full height on mobile */
        position: relative; /* Fix the position */
        top: 10%; /* Center vertically */
        left: 0%; /* Center horizontally */
        transform: translate(-50%, -50%); /* Adjust position to center */
        max-width: fit-content; /* Fit modal to content */
        width: auto; /* Allow width to adjust based on content */
        margin: 0%;
        padding: 1px;
    }

    .custom-modal .modal-content {
        height: fit-content; /* Full viewport height */
        border-radius: 0; /* Remove rounded corners for mobile */
        padding: 0%;
        margin: 0%;

    }

    .custom-modal .modal-header,
    .custom-modal .modal-footer {
        padding: 0%; /* Add padding to header/footer */
        margin: 0%;
    }
}