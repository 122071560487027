  p{
    font-size: 15px;
  }

.full-width-image {
    width: 100%;
    height: auto;
    overflow: hidden;
  }
.border-box {
    border: 2px solid #e0e0e0;
    padding: 15px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    transition: 0.3s;
  }
  .border-box:hover {
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }
 
  .no-border {
    padding: 15px;
  }

  .sub-heading-bg {
    padding: 10px;
    border-radius: 4px;
    color: #9acd32;
    font-weight: bold;
  }
 
  .h2clas {
    color: #0056b3;
    font-weight: bold;
    text-align: center;
  }


  .ulclas{
    background-color: #fbf5ed;
    text-align: left;
    font-size: 15px;
  }


