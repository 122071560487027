/* Navbar adjustments for responsiveness */
.navbarclass {
  /* background-color: #ec9b20; */
  max-height: 53px; /* Set max height */
  margin: 0 auto; /* Center the Navbar */
  width: 65%; /* Use full width */
  z-index: 10;
  left: 30px;
 
}
.navlistclass .nav-link{
  color: #080809;
}

/* Control the height based on screen size */
@media (max-width: 576px) {
  .navbarclassss {
    height: 55px; /* Adjust height for mobile */
  }
  .navlistclass .nav-link{
    color: white;
    background-color: #ffad00;
    width: 94px;
    border-radius: 10px;
  }
  .navlistclass .nav-link:hover{
    color: #9acd32;
  }
}

/* Responsive Navbar visibility */
.navbar-hidden {
  top: -80px;
  transition: top 0.3s ease-in-out;
}

/* Link styles */
.my-custom-link {
  color: #080809;
  font-weight: bold;
  font-size: 16px;
  padding: 8px 12px;
  text-decoration: none; /* Remove underline */
}

/* Hover effects for links */
.my-custom-link:hover {
  color: #9acd32; /* Change color on hover */
}

/* Logo styles */
.logoClasss {
  width: auto;
  height: 50px;
   /* Adjust position */
   z-index: 300;
}
/* Adjust the logo for smaller screens */
@media (max-width: 1200px) {
  .logoClasss {
    width: 70px;
    height: 40px;
  
  }
}
/* Adjust link styles for smaller screens */
@media (max-width: 576px) {
  .my-custom-link {
    font-size: 14px;
    padding: 5px;
  }
}




