.cardmain {
    transition: transform 0.3s ease-in-out;
  }
  
  .cardmain:hover {
    transform: scale(1.05);
  }
  
  .shadowBody {
    padding: 20px;
  }
  
  .shadowBodyTitle {
    font-weight: bold;
    font-size: 18px;
  }
  