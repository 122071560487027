/* The container wrapping the whole image and the heading */
.containerfranchise {
    position: relative;
    max-width: 100%; /* Full width */
    padding-top: 43px; /* Top margin for spacing */
  }
  /* The container wrapping the whole image and the heading */
.containerfranchise2 {
    position: relative;
    max-width: 100%; /* Full width */
    padding-top: 0px; /* Top margin for spacing */
  }
  .image-container{
    background-color: white;
  }
  .startinvestment{
  
    margin-left: 40px;
    height: 440px;
    width: 300px;

  }
  
   /* Style for the heading div (text overlaying the image) */
  .paragraphdiv {
    position: relative;
    margin-left: 40px;
    top: 110px; /* Vertically center the text */
    left: 40%; /* Place the text 10% from the left */
    z-index: 10; /* Ensure the text is above the image */
    color: white; /* White text to stand out */
    font-weight: bold;
    /* text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.7); Optional text shadow */
    
  }

  .headdingdiv p{
    font-size: 35px;
    line-height: 1.4;
  }
  .paragraphdiv{
    height: auto;
    width: 500px;
  }
  .bussinesscol{
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-color: #e2ecfc;
  }
  
  .investmentcol1 {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 400px;
    color: white;
  }
   
  .investmentcol2 {
    display: flex;
    justify-content: center;
    align-items: center;  
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 400px;
    color: white;
    /* filter: blur(0.6px); */
  }
  .patner{
    width: 400px;
    height: 300px;

  }
  .patnercol{
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .checkboxcol1 {
    font-size: 40px; /* Adjust the size to your preference */
    color: #28a745; /* Optional: Change the color of the icon */
    margin-right: 10px; /* Optional: Space between icon and text */
  }
  .custom-icon-list {
    list-style: none; /* Remove default bullet points */
    padding-left: 300px; /* Remove padding */
    margin: 0; /* Remove margin */
    font-size: 20px;
    font-weight: bold;
  }
  .benifitimage{
    width: 300px;
  }

  .text-center-investment{
    width: 200px;
    height: 200px;
  }
  .ocupiieimage{
    min-width: 300px;
    height: 200px;
  }
.joinhand{
  font-size: 25px;
}
.ocupiiehos{
  font-size: 35px;
}
.startyour{
  font-size: 28px;
  margin-top: 10px;
}
.startyour span{
  margin-top: 10px;
}
.enquirenowss{
  width: fit-content;
}
.enquirenowss:hover{
  background-color: #1515ec;
  color: #ffad00;
  transform: translateY(-60%) scale(1.1); /* Move the button up and scale it */
  box-shadow: 0px 6px 15px rgba(180, 186, 180, 0.3); /* Increase shadow on hover */
  cursor: pointer; /* Change cursor to pointer for better UX */
  
}
.enquirenowss{
  @media(min-width: 768px) and (max-width: 1920px){
    font-size: 28px;
  }
}

.enquirenowss{
  @media(min-width: 320px) and (max-width: 768px){
    font-size: 16px;
  }
}

@media (max-width: 1024px){
  .paragraphdiv {
    position: relative;
    top: 110px; /* Vertically center the text */
    left: 5%; /* Place the text 10% from the left */
    z-index: 10; /* Ensure the text is above the image */
    color: white; /* White text to stand out */
    font-size: 60px; /* Adjust font size as needed */
    font-weight: bold;
    /* text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.7); Optional text shadow */
    
  }
  .headdingdiv p{
    font-size: 32px;
    line-height: 1.2;
  }
}

  @media (max-width: 992px){
    .paragraphdiv {
      position: relative;
      top: 110px; /* Vertically center the text */
      left: 5%; /* Place the text 10% from the left */
      z-index: 10; /* Ensure the text is above the image */
      color: white; /* White text to stand out */
      font-size: 60px; /* Adjust font size as needed */
      font-weight: bold;
      /* text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.7); Optional text shadow */
      
    }
    .headdingdiv p{
      font-size: 35px;
      line-height: 1.2;
    }
    .custom-icon-list {
      list-style: none; /* Remove default bullet points */
      padding-left: 100px; /* Remove padding */
      margin: 0; /* Remove margin */
      font-size: 18px;
      font-weight: bold;
    }
   }

 @media (max-width: 768px){
  .paragraphdiv {
    position: relative;
    top: 110px; /* Vertically center the text */
    left: 5%; /* Place the text 10% from the left */
    z-index: 10; /* Ensure the text is above the image */
    color: white; /* White text to stand out */
    font-size: 25px; /* Adjust font size as needed */
    font-weight: bold;
    /* text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.7); Optional text shadow */
    
  }
  .headdingdiv p{
    font-size: 27px;
    line-height: 1.4;
  }
  .custom-icon-list {
    list-style: none; /* Remove default bullet points */
    padding-left: 30px; /* Remove padding */
    margin: 0; /* Remove margin */
    font-size: 18px;
    font-weight: bold;
  }
 }
  
  

  /* Responsive adjustments for smaller screens */
  @media (max-width: 576px) {
    .startinvestment {
      width: 100%; /* Make image responsive */
      max-width: 100%;
      display: none;
    }
    .paragraphdiv{
        top: 0%;
        left: 0%;
        width: fit-content;
        height: fit-content;
    }
    .headdingdiv p{
        font-size: 16px;
        line-height: 1.2;
      }
      .bussinesscol h1{
        font-size: 16px;
      }
      .headdingdiv{
        margin-top: 15px;
      }

      .investmentcol1 {
        display: flex;
        justify-content: center;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        height: 200px;
        min-width: 300px;
        color: white;
      }
      .investmentcol2 {
        display: flex;
        justify-content: center;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        height: 200px;
        min-width: 300px;
        color: white;
      }
      .impressiveReturn h1{
        font-size: 16px;
      }
      .patner{
        width: 320px;
        height: 300px;
    
      }
      .patnercol p{
        font-size: 4px;
      }
      .ocupiiereliable p{
        font-size: 16px;
      }
      .joinhand{
        font-size: 16px;
      }
      .ocupiiehos{
        font-size: 18px;
      }
      .startyour{
        font-size: 18px;
        margin-top: 0px;
      }
      .startyour span{
       margin-top: 0px;
      }
      .custom-icon-list {
        list-style: none; /* Remove default bullet points */
        padding-left: 0px; /* Remove padding */
        margin: 0; /* Remove margin */
        font-size: 18px;
        font-weight: bold;
      }
      .benifitimage{
        width: 300px;
        display: none;
      }
  }
  