.custom-list-item {
    background-color: #9acd32; /* Custom background color */
    color: #080809;              /* Custom text color */
    padding: 0%;
    min-height: 40px;
  }
  
  .custom-list-item:hover {
    background-color: #ffad00;
    color: white; /* Custom hover effect */
  }
  .sectionButton{
    background-color: 'transparent';
    color: '#007bff';
  } 
 /* Active course link styling */
.active-course-link{
  font-weight: bold;        /* Make the active course bold */
  color: #28a745;           /* Custom color for active link */
  
}

/* Button styling for small screens (max-width: 576px) */
@media (max-width: 576px) {
  .toggle-course-btn {
    margin-top: 10px; /* Add margin for spacing */
    margin-bottom: 15px; /* Adjust the spacing around the button */
    color: #080809; /* Red text color */
    font-weight: bold; /* Optional for better visibility */
    background-color: #ffad00;
  }

  .toggle-course-btn:hover {
    color: darkred; /* Darken the red on hover for effect */
  }
}

/* Other general styles */
.sectionButton {
  margin-bottom: 10px;
}

.custom-list-item {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}


